//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Top Bar
//  56. Xy Grid
//  57. CAS settings
//  58. Icon Fonts
@import 'foundation/util/util';

$contrast-warnings: false;
// 1. Global
// ---------
$assets-path: '../images/';
$svg-icons-path: '../images/icons/';
$global-font-size: 14px;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$foundation-palette: (
    primary: #1E7EB0,
    secondary: #EC2748,
    success: #27AE60,
    warning: #E67E22,
    info: #A36D8F,
    alert: #EC2748,
    'blue': #1E7EB0,
    'cyan': #1E85BB,
    'magenta': #fa2a92,
    'navy': #34495E,
    'midnight': #22303d,
    'green': #238247,
    'violet': #A36D8F,
    'orange': #e74c3c,
    'light-yellow': #F39C12,
    'sea-green': #1ABC9C,
    'dark-yellow': #E67E22,
    'teal': #5FAEB4,
    'ice': #efefef,
    'vanilla': #F1E6E5,
    'yellow': #f9c01a
);
$button-only-palette: (
    primary: #1E85BB,
    secondary: #EC2748,
);
$button-only-hover-palette: (
    primary: #17668f,
    secondary: #ce1232,
);
$cyan-hover: #17668f;
$light-gray: #efefef;
$medium-gray: #707070;
$ui-gray: #aaaaaa;
$ui-light-gray: mix($light-gray, $ui-gray, 50%);
$dark-gray: #3D3B3B;
$black: #000000;
$dark-gray-bg: darken($dark-gray, 10%);
$white: #ffffff;
$off-white: #f7f7f7;
$button-neutral-palette: (
    medium-gray: $medium-gray,
    ui-gray: $ui-gray,
    light-gray: $light-gray,
);
$body-background: $white;
$body-font-color: $dark-gray;
$body-font-family: "open-sans", "Segoe UI", Arial, Tahoma, sans-serif;
$sec-font: 'factoria', "open-sans", Georgia, serif;
$work-sans: $sec-font;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-light: 300;
$global-weight-normal: 400;
$global-weight-semibold: 600;
$global-weight-bold: 700;
$global-weight-extrabold: 800;
$global-radius: 0;
$global-border-color: #cccccc;
$global-border: 1px solid $global-border-color;
$global-menu-padding: rem-calc(14 25.5);
$global-menu-padding-large: rem-calc(10 17);
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
$global-prototype-breakpoints: false;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

$cas-note-overlay: adjust-color(get-color(primary), $lightness: 54%) !default;
$cas-alert-overlay: adjust-color(get-color(alert), $saturation: 10%, $lightness: 43.5%) !default;
$cas-warning-overlay: adjust-color(get-color(warning), $saturation: 4.91%, $lightness: 44%) !default;
$cas-info-overlay: adjust-color(get-color(info), $lightness: 40%) !default;
$cas-success-overlay: #f7fffb !default;

// 2. Breakpoints
// --------------

$breakpoints: (
        small: 0,
        medium: 768px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1920px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
        small: 10px,
        medium: 20px,
);
$grid-column-align-edge: true;
$grid-column-alias: false;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: 700;
$header-font-style: normal;
$font-family-monospace: 'Consolas', "Lucida Console", Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-styles: (
        small: (
            'h1': ('font-size': rem-calc(30), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h2': ('font-size': rem-calc(24), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h3': ('font-size': rem-calc(20), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h4': ('font-size': rem-calc(16), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h5': ('font-size': rem-calc(14), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h6': ('font-size': rem-calc(14), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5))
        ),
        medium: (
            'h1': ('font-size': rem-calc(32), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h2': ('font-size': rem-calc(28), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h3': ('font-size': rem-calc(22), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h4': ('font-size': rem-calc(16), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h5': ('font-size': rem-calc(14), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h6': ('font-size': rem-calc(14), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5))
        ),
        large: (
            'h1': ('font-size': rem-calc(36), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h2': ('font-size': rem-calc(30), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h3': ('font-size': rem-calc(24), 'line-height': 1.1, 'margin-top': rem-calc(21), 'margin-bottom': rem-calc(10.5)),
            'h4': ('font-size': rem-calc(18), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h5': ('font-size': rem-calc(16), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5)),
            'h6': ('font-size': rem-calc(14), 'line-height': 1.1, 'margin-top': rem-calc(10.5), 'margin-bottom': rem-calc(10.5))
        ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: rem-calc(12);
$header-small-font-color: $dark-gray;
$paragraph-lineheight: 1.4;
$paragraph-font-size: inherit;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: get-color('midnight');
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 0;
$code-padding: rem-calc(0 7);
$anchor-color: darken(get-color(primary), 10%);
$anchor-color-hover: get-color(primary);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: underline;
$hr-width: $global-width;
$hr-border: 2px solid $ui-light-gray;
$hr-margin: rem-calc(50) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: none;
$list-style-position: outside;
$list-side-margin: 1.5rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 2rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0;
$blockquote-color: $dark-gray;
$blockquote-padding: 3em;
$blockquote-border: 0;
$cite-font-size: 1rem;
$cite-color: $dark-gray;
$cite-pseudo-content: '\2014 \0020';
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.4;
$lead-font-weight: 300;
$lead-font-style: italic;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-bold;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: adjust-color(get-color(alert), $saturation: 13.76%, $lightness: 42.1%);
$form-label-color-invalid: $dark-gray;
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(13);
$input-error-font-weight: 600;

// 7. Accordion
// ------------

$accordion-background: transparent;
$accordion-plusminus: true;
$accordion-title-font-size: rem-calc(19);
$accordion-item-color: $dark-gray;
$accordion-item-background-hover: transparent;
$accordion-item-padding: 1rem 0;
$accordion-content-background: transparent;
$accordion-content-border: 0;
$accordion-content-color: $body-font-color;
$accordion-content-padding: 0 1rem 1rem 0;

// 8. Accordion Menu
// -----------------

$accordionmenu-padding: 0.5rem 2rem 0.5rem 0.5rem;
$accordionmenu-nested-margin: $global-menu-nested-margin;
$accordionmenu-submenu-padding: $accordionmenu-padding;
$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $ui-gray;
$accordionmenu-item-background: null;
$accordionmenu-border: null;
$accordionmenu-submenu-toggle-background: null;
$accordion-submenu-toggle-border: $accordionmenu-border;
$accordionmenu-submenu-toggle-width: 40px;
$accordionmenu-submenu-toggle-height: $accordionmenu-submenu-toggle-width;
$accordionmenu-arrow-size: 6px;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: $white;
$badge-color-alt: $black;
$badge-palette: $foundation-palette;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: 1rem;
$breadcrumbs-item-color: darken($medium-gray, 10%);
$breadcrumbs-item-color-current: $dark-gray;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: '\276F';
$breadcrumbs-item-separator-item-rtl: '\276E';
$breadcrumbs-item-separator-color: $ui-gray;
$breadcrumbs-item-separator-size: rem-calc(12);

// 11. Button
// ----------
$button-font-family: inherit;
$button-padding : rem-calc(14 20);
$button-padding-large: rem-calc(14 20);
$button-margin: 0 ;
$button-fill: solid;
$button-background: darken(get-color(primary), 5%);
$button-background-hover-lightness: -24%;
$button-background-hover: scale-color($button-background, $lightness: $button-background-hover-lightness);
$button-color: $white;
$button-color-alt: $white;
$button-radius: 0;
$button-hollow-border-width: 2px;
$button-sizes: (
        tiny: 0.6rem,
        small: 0.75rem,
        default: 1rem,
        large: 1.25rem,
);
$button-palette: $button-only-palette;
$button-hover-palette: $button-only-hover-palette;
$button-opacity-disabled: 1;
$button-hollow-hover-lightness: 10%;
$button-transition: 0.3s;
$button-box-shadow: 0px 1px 2px 0 rgba($black, 0.6);
$button-hover-box-shadow: 0px 1px 4px 0 rgba($black, 0.6);
$button-active-box-shadow: 0px 1px 4px 0 rgba($black, 0.6);
$button-hollow-border: 2px solid lighten($button-background, 20%);
$button-hollow-box-shadow: none;
$button-hollow-hover-box-shadow: none;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;
$buttongroup-radius-on-each: true;

// 13. Callout
// -----------

$callout-background: $cas-note-overlay;
$callout-background-fade: 0%;
$callout-border: 1px solid get-color(primary);
$callout-margin: 1.5rem 0;
$callout-width: 5%;
$callout-width-xlarge: 12%;
$callout-width-xxlarge: 8%;
$callout-icon-size: 20px;
$callout-icon-size-xlarge: 24px;
$callout-icon-size-xxlarge: 26px;
$callout-padding: .5rem 1rem .5rem 3rem;
$callout-note-padding: $callout-padding;
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 100%;

// 14. Card
// --------

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 1px solid $light-gray;
$card-shadow: $global-box-shadow;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin-bottom: $global-margin;

// 15. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: (
        small: 1rem,
        medium: 1rem,
);
$closebutton-offset-vertical: (
        small: 1rem,
        medium: 1rem,
);
$closebutton-size: (
        small: 1.25rem,
        medium: 1.25rem,
);
$closebutton-lineheight: 1;
$closebutton-color: $ui-gray;
$closebutton-color-hover: $dark-gray;

// 16. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-padding: $global-menu-padding;
$drilldown-nested-margin: 0;
$drilldown-background: $white;
$drilldown-submenu-padding: $drilldown-padding;
$drilldown-submenu-background: $white;
$drilldown-arrow-color: $primary-color;
$drilldown-arrow-size: 6px;

// 17. Dropdown
// ------------

$dropdown-padding: 1rem;
$dropdown-background: $body-background;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
        tiny: 100px,
        small: 200px,
        large: 400px,
);

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $dark-gray;
$dropdownmenu-arrow-size: 4px;
$dropdownmenu-arrow-padding: 1.5rem;
$dropdownmenu-min-width: 500px;
$dropdownmenu-background: $white;
$dropdownmenu-submenu-background: $off-white;
$dropdownmenu-padding: 0;
$dropdownmenu-nested-margin: 0;
$dropdownmenu-submenu-padding: .5rem;
$dropdownmenu-border: 0;
$dropdown-menu-item-color-active: get-color(primary);
$dropdown-menu-item-background-active: transparent;

// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 20. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(28 0 12);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(28.5);
$helptext-color: darken($medium-gray, 10%);
$helptext-font-size: rem-calc(13);
$helptext-font-style: normal;
$input-prefix-color: $ui-gray;
$input-prefix-background: transparent;
$input-prefix-border: 1px solid transparent;
$input-prefix-padding: 1rem;
$form-label-color: $dark-gray;
$form-label-font-size: 1rem;
$form-label-font-weight: bold;
$form-label-line-height: 1.2;
$input-color: $dark-gray;
$input-placeholder-color: mix($light-gray, $medium-gray, 50%);
$input-font-family: inherit;
$input-font-size: 1rem;
$input-font-weight: $global-weight-normal;
$input-line-height: 1;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: $global-border;
$input-border-focus: 1px solid change-color(get-color(primary), $lightness: 57.45%);
$input-padding: rem-calc(15);
$input-shadow: none;
$input-shadow-focus: $input-shadow;
$input-cursor-disabled: not-allowed;
$input-transition: border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $button-radius;
$select-background: $white;
$select-triangle-color: $medium-gray;
$select-shadow: $input-shadow;

// 21. Label
// ---------

$label-background: $primary-color;
$label-color: $white;
$label-color-alt: $white;
$label-palette: $foundation-palette;
$label-font-size: rem-calc(13);
$label-font-weight: $global-weight-bold;
$label-padding: rem-calc(5) 0.5rem rem-calc(7);
$label-radius: $global-radius;

// 22. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 23. Menu
// --------

$menu-margin: 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-items-padding-large: $global-menu-padding-large;
$menu-simple-margin: 1rem;
$menu-item-color-active: $white;
$menu-item-background-active: transparent;
$menu-icon-spacing: 0.25rem;
$menu-item-background-hover: $light-gray;
$menu-state-back-compat: true;
$menu-centered-back-compat: true;

// 24. Meter
// ---------

$meter-height: 1rem;
$meter-radius: $global-radius;
$meter-background: $medium-gray;
$meter-fill-good: $success-color;
$meter-fill-medium: $warning-color;
$meter-fill-bad: $alert-color;

// 25. Off-canvas
// --------------

$offcanvas-sizes: (
        small: 300px,
        medium: 320px
);
$offcanvas-vertical-sizes: (
        small: 300px,
        medium: 320px
);
$offcanvas-background: get-color('vanilla');
$offcanvas-shadow: 1px 1px 1px 0 rgba($black, 0.65);
$offcanvas-inner-shadow-size: 20px;
$offcanvas-inner-shadow-color: rgba($black, 0.25);
$offcanvas-overlay-zindex: 11;
$offcanvas-push-zindex: 12;
$offcanvas-overlap-zindex: 13;
$offcanvas-reveal-zindex: 12;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';

// 26. Orbit
// ---------

$orbit-bullet-background: $light-gray;
$orbit-bullet-background-active: $medium-gray;
$orbit-bullet-diameter: rem-calc(12);
$orbit-bullet-margin: rem-calc(2.5);
$orbit-bullet-margin-top: rem-calc(20);
$orbit-bullet-margin-bottom: rem-calc(20);
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: transparent;
$orbit-control-padding: 1.5rem;
$orbit-control-zindex: 10;
$orbit-control-box-shadow: 1px 1px 1px rgba($black, 0.7);
$orbit-control-box-shadow2: 1px 1px 1px rgba($black, 1);

// 27. Pagination
// --------------

$pagination-font-size: 1rem;
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $ui-gray;
$pagination-item-padding: rem-calc(7 8);
$pagination-item-spacing: 0;
$pagination-radius: $global-radius;
$pagination-item-background-hover: transparent;
$pagination-item-background-current: transparent;
$pagination-item-color-current: $dark-gray;
$pagination-item-color-disabled: $ui-gray;
$pagination-ellipsis-color: $ui-gray;
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: false;

// 28. Progress Bar
// ----------------

$progress-height: rem-calc(15);
$progress-background: #ececec;
$progress-margin-bottom: $global-margin;
$progress-meter-background: get-color('green');
$progress-box-shadow: inset 2px 2px 2px 0px rgba($black, 0.2);
$progress-radius: 5000px;

// 29. Prototype Arrow
// -------------------

$prototype-arrow-directions: (
        down,
        up,
        right,
        left
);
$prototype-arrow-size: 0.4375rem;
$prototype-arrow-color: $black;

// 30. Prototype Border-Box
// ------------------------

$prototype-border-box-breakpoints: $global-prototype-breakpoints;

// 31. Prototype Border-None
// -------------------------

$prototype-border-none-breakpoints: $global-prototype-breakpoints;

// 32. Prototype Bordered
// ----------------------

$prototype-bordered-breakpoints: $global-prototype-breakpoints;
$prototype-border-width: rem-calc(1);
$prototype-border-type: solid;
$prototype-border-color: $medium-gray;

// 33. Prototype Display
// ---------------------

$prototype-display-breakpoints: $global-prototype-breakpoints;
$prototype-display: (
        inline,
        inline-block,
        block,
        table,
        table-cell
);

// 34. Prototype Font-Styling
// --------------------------

$prototype-font-breakpoints: $global-prototype-breakpoints;
$prototype-wide-letter-spacing: rem-calc(4);
$prototype-font-normal: $global-weight-normal;
$prototype-font-bold: $global-weight-bold;

// 35. Prototype List-Style-Type
// -----------------------------

$prototype-list-breakpoints: $global-prototype-breakpoints;
$prototype-style-type-unordered: (
        disc,
        circle,
        square
);
$prototype-style-type-ordered: (
        decimal,
        lower-alpha,
        lower-latin,
        lower-roman,
        upper-alpha,
        upper-latin,
        upper-roman
);

// 36. Prototype Overflow
// ----------------------

$prototype-overflow-breakpoints: $global-prototype-breakpoints;
$prototype-overflow: (
        visible,
        hidden,
        scroll
);

// 37. Prototype Position
// ----------------------

$prototype-position-breakpoints: $global-prototype-breakpoints;
$prototype-position: (
        static,
        relative,
        absolute,
        fixed
);
$prototype-position-z-index: 975;

// 38. Prototype Rounded
// ---------------------

$prototype-rounded-breakpoints: $global-prototype-breakpoints;
$prototype-border-radius: rem-calc(3);

// 39. Prototype Separator
// -----------------------

$prototype-separator-breakpoints: $global-prototype-breakpoints;
$prototype-separator-align: center;
$prototype-separator-height: rem-calc(2);
$prototype-separator-width: 3rem;
$prototype-separator-background: $primary-color;
$prototype-separator-margin-top: $global-margin;

// 40. Prototype Shadow
// --------------------

$prototype-shadow-breakpoints: $global-prototype-breakpoints;
$prototype-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16),
0 2px 10px 0 rgba(0, 0, 0, .12);

// 41. Prototype Sizing
// --------------------

$prototype-sizing-breakpoints: $global-prototype-breakpoints;
$prototype-sizing: (
        width,
        height
);
$prototype-sizes: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
);

// 42. Prototype Spacing
// ---------------------

$prototype-spacing-breakpoints: $global-prototype-breakpoints;
$prototype-spacers-count: 3;

// 43. Prototype Text-Decoration
// -----------------------------

$prototype-decoration-breakpoints: $global-prototype-breakpoints;
$prototype-text-decoration: (
        overline,
        underline,
        line-through,
);

// 44. Prototype Text-Transformation
// ---------------------------------

$prototype-transformation-breakpoints: $global-prototype-breakpoints;
$prototype-text-transformation: (
        lowercase,
        uppercase,
        capitalize
);

// 45. Prototype Text-Utilities
// ----------------------------

$prototype-utilities-breakpoints: $global-prototype-breakpoints;
$prototype-text-overflow: ellipsis;

// 46. Responsive Embed
// --------------------

$responsive-embed-margin-bottom: rem-calc(16);
$responsive-embed-ratios: (
        default: 4 by 3,
        widescreen: 16 by 9,
);

// 47. Reveal
// ----------

$reveal-background: $off-white;
$reveal-width: 700px;
$reveal-max-width: $global-width;
$reveal-padding: $global-padding*1.5 $global-padding*2 $global-padding*2;
$reveal-box-shadow: $global-box-shadow;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black,.85);

// 48. Slider
// ----------

$slider-width-vertical: 0.5rem;
$slider-transition: all 0.2s ease-in-out;
$slider-height: 0.5rem;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;

// 49. Switch
// ----------

$switch-background: $light-gray;
$switch-background-active: $light-gray;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: 5000px;
$switch-margin: $global-margin;
$switch-paddle-background: $ui-gray;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: 50%;
$switch-paddle-transition: all 0.25s ease-out;
$switch-paddle-transition: all 0.25s ease-out;

// 50. Table
// ---------

$table-background: transparent;
$table-color-scale: 5%;
$table-border: none;
$table-padding: rem-calc(20 17 22);
$table-hover-scale: 2%;
$table-row-hover: transparent;
$table-row-stripe-hover: transparent;
$table-is-striped: false;
$table-striped-background: transparent;
$table-stripe: even;
$table-head-background: transparent;
$table-head-row-hover: transparent;
$table-foot-background: transparent;
$table-foot-row-hover: transparent;
$table-head-font-color: $medium-gray;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;
$table-stack-breakpoint: medium;

// 51. Tabs
// --------

$tab-margin: 0;
$tab-background: $light-gray;
$tab-color: $medium-gray;
$tab-background-active: $white;
$tab-active-color: get-color('cyan');
$tab-item-font-size: 1rem;
$tab-item-background-hover: $white;
$tab-item-padding: rem-calc(12.5) 2rem;
$tab-expand-max: 6;
$tab-content-background: $white;
$tab-content-border: $light-gray;
$tab-content-color: $body-font-color;
$tab-content-padding: 1.5rem 2rem;

// 52. Thumbnail
// -------------

$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 53. Title Bar
// -------------

$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 54. Tooltip
// -----------

$has-tip-cursor: help;
$has-tip-color: get-color('cyan');
$has-tip-icon-size: rem-calc(20);
$has-tip-font-weight: $global-weight-semibold;
$has-tip-border-bottom: 0;
$tooltip-background-color: $dark-gray;
$tooltip-color: $white;
$tooltip-padding: 1rem;
$tooltip-max-width: 20rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: .5rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $global-radius;

// 55. Top Bar
// -----------

$topbar-padding: 0.5rem;
$topbar-background: $light-gray;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 0.5rem 1rem 0.5rem 0;
$topbar-input-width: 200px;
$topbar-unstack-breakpoint: medium;

// 56. Xy Grid
// -----------

$xy-grid: false;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
        small: 20px,
        medium: 30px
);
$grid-padding-gutters: $grid-margin-gutters;
$block-grid-max: 8;

// 57. CAS settings
// -----------
$cas-links-border-bottom: 2px dotted $light-gray !default;
$cas-links-line-height: 1.1 !default;
$cas-links-font-weight: 600 !default;

// 58. Icon Fonts
// -----------
$icomoon-font-family: "CI-Icons" !default;
$icomoon-font-path: "../fonts/CI-Icons" !default;

