.modal-slide.pr-cookie-left-bar .modal-inner-wrap {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-width: 32.1428571429rem;
  font-size: 88%;
}
.modal-slide.pr-cookie-left-bar .pr-cookie-close {
  top: 0;
  right: 0;
}
.modal-slide.pr-cookie-left-bar .modal-content {
  position: relative;
}
.modal-slide.pr-cookie-left-bar .pr-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 2rem;
}
.modal-slide.pr-cookie-left-bar .button.small {
  font-size: 100%;
  padding-top: 0.5714285714rem;
  padding-bottom: 0.5714285714rem;
}

.pr-cookie-title, .pr-cookie-manage-title {
  font-family: "factoria", "open-sans", Georgia, serif;
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.7142857143rem;
}

.message-system-list li {
  margin-bottom: 1.5rem;
}
.message-system-list li:last-child {
  margin-bottom: 0;
}

.pr-cookie-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.pr-cookie-option .option-label {
  font-size: 1.2142857143rem;
}
.pr-cookie-option .option-status {
  font-size: 1rem;
  color: #238247;
}

.pr-cookie-details {
  color: #175f84;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.2142857143rem;
  font-size: 100%;
  margin-top: 0.5rem;
}
.pr-cookie-details.button.flat {
  border-radius: 0;
  border-bottom: 2px dotted #cdcdcd;
}
.pr-cookie-details.button.flat:hover {
  border-bottom-color: transparent;
}
.pr-cookie-details:hover {
  border: 0;
  color: #1E7EB0;
}

.pr-cookie-switch .switch-input {
  height: 1.7142857143rem;
  width: 3rem;
  left: 0;
  margin-bottom: 0;
}
.pr-cookie-switch .switch-input:checked + .switch-paddle:after {
  left: 2rem;
  background-color: #238247;
}
.pr-cookie-switch .switch-active-icon, .pr-cookie-switch .switch-inactive-icon {
  font-size: 0.8571428571rem;
}
.pr-cookie-switch .switch-active-icon {
  color: #238247;
}
.pr-cookie-switch .switch-paddle {
  height: 1.5rem;
  width: 3rem;
}
.pr-cookie-switch .switch-paddle:after {
  width: 1.3571428571rem;
  height: 1.3571428571rem;
  margin-top: -0.6428571429rem;
}

.button.medium-gray {
  color: #707070;
}
.button.medium-gray.hollow {
  background-color: transparent;
  border: 1px solid #8a8a8a;
  box-shadow: none;
}
.button.medium-gray.hollow:hover, .button.medium-gray.hollow:active, .button.medium-gray.hollow:focus {
  color: #707070;
  box-shadow: none;
  background-color: rgba(112, 112, 112, 0.2);
  border: 1px solid #8a8a8a;
}
.button.medium-gray.hollow:hover.dark:hover, .button.medium-gray.hollow:hover.dark:active, .button.medium-gray.hollow:hover.dark:focus, .button.medium-gray.hollow:active.dark:hover, .button.medium-gray.hollow:active.dark:active, .button.medium-gray.hollow:active.dark:focus, .button.medium-gray.hollow:focus.dark:hover, .button.medium-gray.hollow:focus.dark:active, .button.medium-gray.hollow:focus.dark:focus {
  box-shadow: #22303d 0 0 0 inset;
}
.button.medium-gray.hollow:hover.light:hover, .button.medium-gray.hollow:hover.light:active, .button.medium-gray.hollow:hover.light:focus, .button.medium-gray.hollow:active.light:hover, .button.medium-gray.hollow:active.light:active, .button.medium-gray.hollow:active.light:focus, .button.medium-gray.hollow:focus.light:hover, .button.medium-gray.hollow:focus.light:active, .button.medium-gray.hollow:focus.light:focus {
  box-shadow: #efefef 0 0 0 inset;
}

.pr-cookie-view-top .title {
  display: block;
  margin: 1rem 0 0;
  font-family: "factoria", "open-sans", Georgia, serif;
  font-weight: 700;
  font-size: 1.7857142857rem;
}

.pr-cookie-view-title {
  font-size: 1.5714285714rem;
  margin-bottom: 1rem;
}

.pr-cookie-details-info summary {
  cursor: pointer;
}
.pr-cookie-details-info .pr-cookie-details-title {
  display: inline-block;
  margin-top: 0;
}
.pr-cookie-details-info .pr-cookie-group-view {
  margin-left: 1rem;
  color: #175f84;
}
.pr-cookie-details-info .pr-cookie-group {
  margin: 1.5rem 0;
  border: 1px solid #cdcdcd;
}
.pr-cookie-details-info .pr-cookie-group-row {
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: flex-start;
}
.pr-cookie-details-info .pr-cookie-group-row:last-child {
  border-bottom: 0;
}
.pr-cookie-details-info .pr-cookie-property {
  width: 22%;
  padding: 0.3571428571rem 0.7142857143rem;
  font-weight: 700;
}
.pr-cookie-details-info .pr-cookie-property-value {
  padding: 0.3571428571rem 0.7142857143rem;
  border-left: 1px solid #cdcdcd;
}