//For branded color button
@mixin cas-btn($btn-name, $btn-color, $btn-type:'solid') {
  @if ($btn-type == 'hollow') {
    .button.#{$btn-name} {
      &.hollow {
        border: 1px solid lighten(get-color(#{$btn-color}), 20%);
        background-color: transparent;
          color: get-color(#{$btn-color});
        &:hover, &:active, &:focus {
          border: 1px solid lighten(get-color(#{$btn-color}), 20%);
          @if ($btn-name == 'blue') {
            background-color: rgba(get-color(#{$btn-color}), .15);
          } @else {
            background-color: rgba(get-color(#{$btn-color}), .2);
          }
        }
      }
    }
  } @else {
    .button.#{$btn-name} {
      box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), get-color(#{$btn-color}) -10rem 0 0 0 inset;
      background-color: get-color(#{$btn-color});
      @if ($btn-name == 'yellow') {
        color: $dark-gray;
      }
      &:hover, &:active {
        box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), lighten(get-color(#{$btn-color}), 5%) 0 0 0 inset;
        background-color: scale-color(get-color(#{$btn-color}), $lightness: $button-background-hover-lightness);
      }
        &:focus {
            outline: 2px solid transparent;
            box-shadow:  0px 0px 0 2px $white, 0px 0px 4px 4px lighten(get-color(#{$btn-color}), 5%);
        }
    }
  }
}

//For custom color button (not included in branded color palette)
@mixin custom-btn($btn-name, $btn-color, $btn-type: 'solid') {
  @if ($btn-type == 'hollow') {
    .button.#{$btn-name} {
      &.hollow {
        border: 1px solid lighten($btn-color, 20%);
        background-color: transparent;
        color: lighten($btn-color, 5%);
        &:hover, &:active, &:focus {
          border: 1px solid lighten($btn-color, 20%);
          background-color: rgba($btn-color, .2);
        }
      }
    }
  } @else {
    .button.#{$btn-name} {
      box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), $btn-color -10rem 0 0 0 inset;
      background-color: $btn-color;
      &:hover, &:active, &:focus {
        box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), lighten($btn-color, 5%) 0 0 0 inset;
        background-color: scale-color($btn-color, $lightness: $button-background-hover-lightness);
      }
    }
  }
}

//For neutral color button (not included in branded color palette, doesn't have solid button type)
@mixin neut-btn($btn-name, $btn-color, $btn-type: 'hollow') {
  @if ($btn-type == 'flat') {
    .button {
      &.flat {
        color: $btn-color;
        &:hover {
          background-color: lighten($btn-color, 20%);
          @if ($btn-name == 'medium-gray') {
            color: lighten($btn-color, 20%);
          } @else {
            color: darken($btn-color, 20%);
          }
        }
      }
    }
  } @else {
    .button.#{$btn-name} {
        @if ($btn-name == 'ui-gray') {
            color: darken($btn-color, 20%);
        } @else {
            color: $btn-color;
        }
      &.hollow {
        background-color: transparent;
        @if ($btn-name == 'ui-gray') {
          border: 1px solid $btn-color;
        } @else {
          border: 1px solid lighten($btn-color, 10%);
        }
        box-shadow: $button-hollow-box-shadow;
        &:hover, &:active, &:focus {
            @if ($btn-name == 'ui-gray') {
                color: darken($btn-color, 20%);
            } @else {
                color: $btn-color;
            }
          box-shadow: $button-hollow-hover-box-shadow;
          background-color: rgba($btn-color, .2);
          @if ($btn-name == 'ui-gray') {
            border: 1px solid $btn-color;;
          } @else {
            border: 1px solid lighten($btn-color, 10%);
          }
          &.dark {
            &:hover, &:active, &:focus {
              box-shadow: get-color('midnight') 0 0 0 inset;
            }
          }
          &.light {
            &:hover, &:active, &:focus {
              box-shadow: get-color('ice') 0 0 0 inset;
            }
          }
        }
      }
    }
  }
}

@mixin link-to-solid-btn{
  display: inline-block;
  vertical-align: middle;
  padding: $button-padding;
  -webkit-appearance: none;
  border: 1px solid #0000;
  border-radius: $button-radius;
  transition: $button-transition;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  background-color: $primary-color;
  color: $button-color;
  box-shadow: $button-box-shadow;
  &:hover{
    box-shadow: $button-hover-box-shadow;
  }
}
@mixin link-to-hollow-btn{
  display: inline-block;
  vertical-align: middle;
  padding: $button-padding;
  -webkit-appearance: none;
  border: 1px solid lighten($primary-color, 20%);
  border-radius: $button-radius;
  transition: $button-transition;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  background-color: transparent;
  color: $primary-color;
    cursor: pointer;
  &:hover{
      color: $primary-color;
    background-color: rgba(lighten($primary-color, 20%), .1);
  }
}
