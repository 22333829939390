@import '../../../styles/settings';
@import '../../../styles/cas/mixins';

.modal-slide.pr-cookie-left-bar {
    .modal-inner-wrap {
        position: relative;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        max-width: rem-calc(450);
        font-size: 88%;
    }
    .pr-cookie-close {
        top: 0;
        right: 0;
    }
    .modal-content {
        position: relative;
    }
    .pr-actions-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.5rem 0 2rem;
    }
    .button.small {
        font-size: 100%;
        padding-top: rem-calc(8);
        padding-bottom: rem-calc(8);
    }
}

.pr-cookie-title, .pr-cookie-manage-title {
    font-family: $work-sans;
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: rem-calc(24);
}

.message-system-list li {
    margin-bottom: 1.5rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.pr-cookie-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .option-label {
        font-size: rem-calc(17);
    }
    .option-status {
        font-size: rem-calc(14);
        color: get-color('green');
    }
}

.pr-cookie-details {
    color: $anchor-color;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: rem-calc(3);
    font-size: 100%;
    margin-top: 0.5rem;
    &.button.flat {
        border-radius: 0;
        border-bottom: 2px dotted $ui-light-gray;
        &:hover {
            border-bottom-color: transparent;
        }
    }
    &:hover {
        border: 0;
        color: $anchor-color-hover;
    }
}

.pr-cookie-switch {
    .switch-input {
        height: rem-calc(24);
        width: 3rem;
        left: 0;
        margin-bottom: 0;
        &:checked {
            + .switch-paddle {
                &:after {
                    left: rem-calc(51 - 23);
                    background-color: get-color('green');
                }
            }
        }
    }
    .switch-active-icon, .switch-inactive-icon {
        font-size: rem-calc(12);
    }
    .switch-active-icon {
        color: get-color('green');
    }
    .switch-paddle {
        height: 1.5rem;
        width: 3rem;
        &:after {
            width: rem-calc(19);
            height: rem-calc(19);
            margin-top: rem-calc(-9);
        }
    }
}

@include neut-btn('medium-gray', $medium-gray, $btn-type: 'hollow');

.pr-cookie-view-top {
    .title {
        display: block;
        margin: 1rem 0 0;
        font-family: $work-sans;
        font-weight: 700;
        font-size: rem-calc(25);
    }
}

.pr-cookie-view-title {
    font-size: rem-calc(22);
    margin-bottom: 1rem;
}

$cookie-list-border: 1px solid $ui-light-gray;

.pr-cookie-details-info {
    summary {
        cursor: pointer;
    }
    .pr-cookie-details-title {
        display: inline-block;
        margin-top: 0;
    }
    .pr-cookie-group-view {
        margin-left: 1rem;
        color: $anchor-color;
    }
    .pr-cookie-group {
        margin: 1.5rem 0;
        border: $cookie-list-border;
        &-row {
            border-bottom: $cookie-list-border;
            display: flex;
            align-items: flex-start;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .pr-cookie-property {
        width: 22%;
        padding: rem-calc(5 10);
        font-weight: 700;
        &-value {
            padding: rem-calc(5 10);
            border-left: $cookie-list-border;
        }
    }
}
